export const UPDATE_ORG = 'UPDATE_ORG';
export const UPDATE_ORGS = 'UPDATE_ORGS';

export interface OrgReducer {
  currentOrganization: Org;
  organizations: Org[];
  isLoading: boolean;
}

export interface Org {
  slug?: string;
  name?: string;
  accessKey?: string;
  recordingWebhook?: string;
  accessSecret?: string;
  id?: string;
  isLoading?: boolean;
  roomsLoading?: boolean;
  rtmpRoomsLoading?: boolean;
  usageLoading?: boolean;
  usage?: Usage[];
  rooms?: Room[];
  rtmp_rooms?: RtmpRoom[],
}

export interface Room {
  id: string;
  name: string;
  producerMinutes: string;
  consumerMinutes: string;
  date: string;
}

export interface RtmpRoom {
  startTime: number;
  endTime: number;
  metadata: any;
  streamId: string;
  hlsUrl: string;
  recordingProps: RecordingProps;
}

export interface RecordingProps {
  240: string;
  360: string;
  576: string;
  thumbnail: string;
}

export interface Usage {
  day: string;
  month: string;
  yeah: string;
  minutes: string;
}

export interface UpdateOrganization {
  type: typeof UPDATE_ORG;
  payload: Org;
}

export interface UpdateOrganizations {
  type: typeof UPDATE_ORGS;
  payload: Org[];
}

export type OrgActions = UpdateOrganizations | UpdateOrganization;