import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk';
import { testReducer } from "./test/reducer";
import { userReducer } from "./user/reducer";
import { organizationReducer } from "./organization/reducer";

const rootReducer = combineReducers({ user: userReducer, test: testReducer, organization: organizationReducer });

export type AppState = ReturnType<typeof rootReducer>;

export default createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

//export default createStore(reducer, applyMiddleware(thunk));