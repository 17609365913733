import { SET_USER, User } from "./types";

export function setUser(user: User) {
  return {
    type: SET_USER,
    payload: {
      ...user,
      loading: false
    }
  }
}