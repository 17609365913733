import { Button, Card, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import firebase from "firebase/app";
import "firebase/auth";
import React from 'react';
import { Intro } from '../intro/Intro';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centerParent: {
      display: "flex", justifyContent: "center", alignItems: "center",
      width: '100vw',
    },
    root: {
      height: '100vh',
      width: '100vw',
      background: 'linear-gradient(45deg, #192a56 30%, #273c75 90%)',
      display: "flex", justifyContent: "column", flexDirection: "row", alignItems: "center"
    }
  }));

export const Invite = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.centerParent}>
        <Intro />
      </div>
    </div >
  )
}
