import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import componentStylesForms from "../argon/assets/theme/components/forms.js";
import componentStyles from "../argon/assets/theme/components/validation.js";
import clsx from "clsx";
import isEmail from "is-email";
import React from "react";

const useStyles = makeStyles(componentStyles);
//@ts-ignore
const useStylesForms = makeStyles(componentStylesForms);


export const ValidatedInput = ({ value, setValue, validationFn, label, type, ...props }) => {
  const classes = { ...useStyles(), ...useStylesForms() };
  const theme = useTheme();
  const [
    styleValidation,
    setstyleValidation,
  ] = React.useState(null);

  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      <OutlinedInput
        {...props}
        type={type || "text"}
        placeholder={label || props.placeholder}
        value={value}
        classes={{
          notchedOutline: clsx({
            [classes.borderWarning]:
              styleValidation === "invalid",
            [classes.borderSuccess]:
              styleValidation === "valid",
          }),
        }}
        onChange={(event) => {
          if (!validationFn(event.target.value, props.kind)) {
            //@ts-ignore
            setstyleValidation("invalid");
          } else {
            //@ts-ignore
            setstyleValidation("valid");
          }
          setValue(event.target.value);
        }}
      />
      {styleValidation === "valid" && (
        <FormHelperText
          component={Box}
          color={theme.palette.success.main + "!important"}
        >
          {props.successText}
        </FormHelperText>
      )}
      {styleValidation === "invalid" && (
        <FormHelperText
          component={Box}
          color={theme.palette.warning.main + "!important"}
        >
          {props.failureText}
        </FormHelperText>
      )}
    </FormGroup>
  )
}

export const ValidatedEmail = (props) => {
  return <ValidatedInput
    {...props}
    validationFn={(value) => {
      return isEmail(value)
    }}
  />
}

export const ValidatedNumber = (props) => {
  return <ValidatedInput
    {...props}
    validationFn={(value) => {
      return !isNaN(value)
    }}
  />
}

export const ValidatedName = (props) => {
  return <ValidatedInput
    {...props}
    validationFn={(value) => {
      if (!value || value?.length === 0) {
        return false;
      }
      var patt = new RegExp("[a-zA-Z]+[ a-zA-Z]*");
      return patt.test(value);
    }}
  />
}

export const ValidatedPassword = (props) => {
  return <ValidatedInput
    {...props}
    validationFn={(value) => {
      return value.length > 5;
    }}
  />
}

export const ValidatedUrl = (props) => {
  return <ValidatedInput
    {...props}
    failureText="Enter valid url"
    validationFn={(value) => {
      if (!value.startsWith("https")) {
        return false;
      }
      var patt = new RegExp("((http|https)://)(www.)?"
        + "[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]"
        + "{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)");
      return patt.test(value);
    }}
  />
}

