import { Grid } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Recordings } from '../components/argon/Recordings';
import SimpleHeader from '../components/argon/SimpleHeader';
import { StartChart } from '../components/argon/StatChart';
import { UserTable } from '../components/argon/UserTable';
import { getTimeLocale, getTimeLocaleWithDate } from '../components/utils';
import { currentOrgSelector } from '../store/organization/selector';
import { RtmpRoomFields } from '../components/RtmpRoomFields';

function Stats() {
  // const classes = useStyles()
  const [users, setUsers] = useState([])
  const [rtmpRoom, setRtmpRoom] = useState({})
  const db = firebase.firestore();
  const { room_id } = useParams();
  const accessKey = useSelector(currentOrgSelector).accessKey;
  const curOrgId = useSelector(currentOrgSelector).id;

  useEffect(() => {
    fetchRoom();
  }, []);

  const fetchRoom = async () => {
    if (!room_id) {
      return;
    }
    var snapshot = await db.collection("orgs").doc(accessKey).collection("rtmp_rooms").doc(`${room_id}`).get();
    setRtmpRoom(snapshot.data())
  }
  // const fetchUser = async () => {
  //   var snapshot = await db.collection("orgs").doc(curOrgId).collection("rtmp_rooms").doc(room_id).get();
  //   let data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  //   data.sort((a, b) => (a.startTime - b.endTime))
  //   setUsers(data)
  // }

  // const getChartData = () => {
  //   let labels = [];
  //   labels.push(startTime - 100);
  //   for (let i = 0; i < users.length; i++) {
  //     if (users[i].start_time)
  //       labels.push(users[i].start_time)
  //   }
  //   for (let i = 0; i < users.length; i++) {
  //     if (users[i].end_time)
  //       labels.push(users[i].end_time)
  //   }
  //   labels.sort();
  //   let MAX_VALUES = 50;
  //   if (labels.length > MAX_VALUES) {
  //     let labels2 = [];
  //     labels2.push(labels[0]);
  //     for (let i = 0; i < MAX_VALUES; i++) {
  //       labels2.push(labels[Math.floor(i * (labels.length/MAX_VALUES))])
  //     }
  //     labels = labels2;
  //   }
  //   // labels.push(startTime - ((endTime + ((endTime - startTime)) / LABEL_COUNT)) / LABEL_COUNT);
  //   const data = [];
  //   for (let i = 0; i < labels.length; i++) {
  //     let freq = 0;
  //     for (let j = 0; j < users.length; j++) {
  //       if (users[j].start_time <= labels[i] && (!users[j].end_time || (users[j].end_time >= labels[i]))) {
  //         freq++;
  //       }
  //     }
  //     data.push(freq);
  //   }
  //   console.error(labels);
  //   return {
  //     labels: labels.map(x => getTimeLocale(x)),
  //     datasets: [
  //       {
  //         label: "Users",
  //         data: data,
  //       },
  //     ]
  //   }
  // }

  return (<>
    <SimpleHeader section={room_id} subsection="Dashboard" />
    <Container
      maxWidth={false}
      component={Box}
      marginTop="-4.5rem"
    // classes={{ root: classes.containerRoot }}
    >
      <Grid container spacing={3}>
        {/* <Grid item xs={12} sm={12} lg={12}>
          <StartChart
            title={endTime - startTime}
            subtitle={"Users"}
            data={getChartData()}
          />
        </Grid> */}
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <RtmpRoomFields rtmp_room={rtmpRoom} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Recordings
            recordings={Object.keys(rtmpRoom.recordingProps || {}).map(x => ({url: rtmpRoom.recordingProps[x]}))}
          />
        </Grid>
      </Grid>
    </Container>
  </>
  )
}

export default Stats;