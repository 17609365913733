import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
// javascipt plugin for creating charts
import Chart from "chart.js";
import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
import componentStyles from "./assets/theme/components/cards/charts/card-sales-value-dots.js";
// @material-ui/icons components
// core components
import { chartExample8, chartOptions, parseOptions } from "./variables/chart.js";


const useStyles = makeStyles(componentStyles);

export function StartChart({ title, subtitle, data }) {
  const classes = useStyles();
  const theme = useTheme();
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  return (
    <>
      <Card
        classes={{
          root: classes.cardRoot,
        }}
      >
        <CardHeader
          title={
            <Box component="span" color={theme.palette.gray[600]}>
              {title}
            </Box>
          }
          subheader={subtitle}
          classes={{ root: classes.cardHeaderRoot }}
          titleTypographyProps={{
            component: Box,
            variant: "h6",
            letterSpacing: "2px",
            marginBottom: "0!important",
            classes: {
              root: classes.textUppercase,
            },
          }}
          subheaderTypographyProps={{
            component: Box,
            variant: "h3",
            marginBottom: "0!important",
            color: "initial",
          }}
        ></CardHeader>
        <CardContent>
          <Box position="relative" height="350px">
            <Line
              data={data}
              options={chartExample8.options}
              getDatasetAtEvent={(e) => console.log(e)}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

