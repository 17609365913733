import firebase from "firebase";
import { Notyf } from 'notyf';
import { Org, UPDATE_ORG, UPDATE_ORGS } from "./types";
const notyf = new Notyf();

export const getOrg = (orgId) => async (dispatch, store) => {
  dispatch({
    type: UPDATE_ORG,
    payload: {
      isLoading: true
    }
  });
  const curResponse = await firebase.firestore().collection('orgs').doc(orgId).get()
  let response = curResponse.data();
  console.log('res' ,response)
  dispatch({
    type: UPDATE_ORG,
    payload: {
      isLoading: false,
      ...response
    }
  });
  dispatch(getUsage(orgId));
  dispatch(getRooms(orgId));
  dispatch(getRtmpRooms(orgId));
}

export const getUsage = (orgId: string) => (dispatch, store) => {
  const dates = _getDates(10);

  let promises = dates.map(docId => firebase
    .firestore()
    .collection('orgs')
    .doc(orgId)
    .collection("usage")
    .doc(docId)
    .get())
  Promise.all(promises)
    .then((querySnapshots: any[]) => {
      let documents: any[] = [];
      querySnapshots.map((snapshot, index) => {

        if (snapshot && snapshot.exists) {
          documents.push({
            date: dates[index],
            producerMinutes: snapshot.data().producerMinutes || 0,
            consumerMinutes: snapshot.data().consumerMinutes || 0,
          })
        } else {
          documents.push({
            date: dates[index],
            producerMinutes: 0,
            consumerMinutes: 0,
          })
        }
      })
      dispatch({
        type: UPDATE_ORG,
        payload: {
          usage: documents.reverse(),
          usageLoading: false
        }
      });
    })
}

export const getRtmpRooms = (orgId: string) => (dispatch, store) => {
  firebase.firestore().collection('orgs').doc(orgId).collection("rtmp_rooms").orderBy("startTime", "desc").limit(40).get().then(querySnapshot => {
    if (querySnapshot.empty) {
      // no rooms found issue fixed
      dispatch({
        type: UPDATE_ORG,
        payload: {
          rtmpRoomsLoading: false,
          rtmp_rooms: []
        }
      });
    } else {
      const documents = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      dispatch({
        type: UPDATE_ORG,
        payload: {
          rtmp_rooms: documents,
          rtmpRoomsLoading: false
        }
      });
    }
  })  
}

export const getRooms = (orgId: string) => (dispatch, store) => {
  firebase.firestore().collection('orgs').doc(orgId).collection("rooms").get().then(querySnapshot => {
    if (querySnapshot.empty) {
      // no rooms found issue fixed
      dispatch({
        type: UPDATE_ORG,
        payload: {
          rooms: [],
          roomsLoading: false
        }
      });
    } else {
      const documents = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      dispatch({
        type: UPDATE_ORG,
        payload: {
          rooms: documents,
          roomsLoading: false
        }
      });
    }
  })
}

export const getOrganizations = (id: string) => async (dispatch, store) => {
  firebase.firestore().collection("users").doc(id).collection("orgs").get().then(async querySnapshot => {
    if (querySnapshot.empty) {
      dispatch({
        type: UPDATE_ORGS,
        payload: []
      })
    } else {
      const promises = querySnapshot.docs.map(doc => firebase.firestore().collection('orgs').doc(doc.id).get());
      let documents = (await Promise.all(promises)).map(x => ({ id: x.id, ...x.data() }));
      dispatch(getOrg(documents[0]?.id));
      dispatch({
        type: UPDATE_ORGS,
        payload: documents
      })
    }
  });
}
/**
 * 
 * Returns the past num dates in the right format
 */
const _getDates = (num: number): string[] => {
  let dates: string[] = [];
  for (let i = 0; i < num; i++) {
    let today = new Date();
    let previousDay = new Date(today);
    previousDay.setDate(previousDay.getDate() - i);
    dates.push(_getDate(previousDay))
  }
  return dates;
}

const _getDate = (date: any): string => {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let dayFormatted;
  let monthFormatted;
  if (day < 10) {
    dayFormatted = "0" + day;
  } else {
    dayFormatted = day;
  }
  if (month < 10) {
    monthFormatted = "0" + month;
  } else {
    monthFormatted = month
  }
  return dayFormatted + "-" + monthFormatted + "-" + year;
}

export const createOrganization = (orgName: string) => async (dispatch, store) => {
  const functionRef = firebase
    .app()
    .functions('us-central1')
    .httpsCallable('addOrg');
  try {
    await functionRef({ name: orgName });
  } catch (e) {
    dispatch(errorHandler("Error while adding new organization. Please try again."));
  }
  dispatch(getOrganizations(firebase?.auth()?.currentUser?.uid || ""));
}

export const updateOrg = (newData: Org) => async (dispatch, store) => {
  const functionRef = firebase
    .app()
    .functions('us-central1')
    .httpsCallable('updateOrg');
  try {
    await functionRef(newData);
    dispatch({
      type: UPDATE_ORG,
      payload: newData
    });
    dispatch(successHandler("Updated"))
  } catch (e) {
    dispatch(errorHandler("Error while updating organization."));
  }
}

export const errorHandler = (error: string) => async (dispatch, store) => {
  notyf.error(error);
}

export const successHandler = (message: string) => async (dispatch, store) => {
  notyf.success(message);
}
