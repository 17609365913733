export const UPDATE_RESPONSE = 'UPDATE_RESPONSE'
export interface Test {
  response: number[];
  isLoading: boolean;
}

export interface UpdateResponseAction {
  type: typeof UPDATE_RESPONSE
  payload: Response
}

export type TestActions = UpdateResponseAction;