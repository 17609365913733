import { Card, IconButton, InputAdornment, InputLabel, makeStyles, OutlinedInput } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Notyf } from 'notyf';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
const notyf = new Notyf();

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: 'center'
  },
  cardRoot: {
    color: "white",
    background:
      "linear-gradient(87deg," +
      theme.palette.primary +
      ",#1a174d)!important",
    padding: 20,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  iconButton: {
    padding: 10,
  }
}))

export const RtmpRoomFields = ({rtmp_room}) => {
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.cardRoot}>
        <InputLabel>Stream ID</InputLabel>
        <OutlinedInput
          value={rtmp_room.sessionId}
          endAdornment={<><InputAdornment position="end">
          </InputAdornment>
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
              >
                <CopyToClipboard onCopy={() => { notyf.success("Copied to clipboard") }} text={rtmp_room.sessionId}>
                  <FileCopyIcon />
                </CopyToClipboard>
              </IconButton>
            </InputAdornment>
          </>}
        />
        <br />
        <InputLabel>RTMP URL</InputLabel>
        <OutlinedInput
          value={rtmp_room.rtmpUrl}
          endAdornment={<>
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
              >
                <CopyToClipboard onCopy={() => { notyf.success("Copied to clipboard") }} text={rtmp_room.rtmpUrl}>
                  <FileCopyIcon />
                </CopyToClipboard>
              </IconButton>
            </InputAdornment>
          </>}
        />
        <br />
        <InputLabel>HLS URL</InputLabel>
        <OutlinedInput
          value={rtmp_room.hlsUrl}
          endAdornment={<>
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
              >
                <CopyToClipboard onCopy={() => { notyf.success("Copied to clipboard") }} text={rtmp_room.hlsUrl}>
                  <FileCopyIcon />
                </CopyToClipboard>
              </IconButton>
            </InputAdornment>
          </>}
        />
        <br/><br/>
        {rtmp_room.recordingDone && <>
          <InputLabel>240p video</InputLabel>
          <OutlinedInput
          value={rtmp_room.recordingProps?.["240"]}
          endAdornment={<>
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
              >
                <CopyToClipboard onCopy={() => { notyf.success("Copied to clipboard") }} text={rtmp_room.recordingProps?.["240"]}>
                  <FileCopyIcon />
                </CopyToClipboard>
              </IconButton>
            </InputAdornment>
          </>}
        />
        <br/><br/>
        <InputLabel>360p video</InputLabel>
        <OutlinedInput
          value={rtmp_room.recordingProps?.["360"]}
          endAdornment={<>
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
              >
                <CopyToClipboard onCopy={() => { notyf.success("Copied to clipboard") }} text={rtmp_room.recordingProps?.["360"]}>
                  <FileCopyIcon />
                </CopyToClipboard>
              </IconButton>
            </InputAdornment>
          </>}
        />
        <br/><br/>
        <InputLabel>576p video</InputLabel>
        <OutlinedInput
          value={rtmp_room.recordingProps?.["576"]}
          endAdornment={<>
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
              >
                <CopyToClipboard onCopy={() => { notyf.success("Copied to clipboard") }} text={rtmp_room.recordingProps?.["576"]}>
                  <FileCopyIcon />
                </CopyToClipboard>
              </IconButton>
            </InputAdornment>
          </>}
        />
        
        </>}
      </Card>
    </div>
  )
}
