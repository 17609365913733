import React from 'react'
import { Stream } from "streamapi";
import { Grid, Card, Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ShareIcon from '@material-ui/icons/Share';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Notyf } from 'notyf';
import { Header } from '../components/felix/Header';

export const EducatorDemo = () => {
  const notyf = new Notyf();

  let room = new  URLSearchParams(window.location.search).get("room") || "demo";
  let consumer = new URLSearchParams(window.location.search).get("consumer")
  let norecord = new  URLSearchParams(window.location.search).get("norecord");
  const name = "User";
  return (
    <>
    <Header />
    <br/>
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={0} sm={0} md={0} lg={2}></Grid>
      <Grid item xs={12} sm={12} md={12} lg={8}>
        <Card style={{padding: 40}}>
          <div>
          <Chip clickable
            style={{marginRight: 13}}
            size="small"
            variant="outlined" 
            label={<ArrowBackIcon style={{fontSize: 16, marginTop: 2}} />}
            color="primary"
            ></Chip>
            <Chip clickable
            style={{marginRight: 13}}
            size="small"
            variant="outlined" 
            label={`IIT JEE`}
            color="primary"
            >IIT JEE</Chip>
            <Chip clickable
              size="small"
              variant="outlined" 
              label={`Free class`}
              color="primary"
              ></Chip>
          </div>  
          <br/><br/>
          <div>
          <Chip clickable
            size="small"
            variant="outlined" 
            label={`Hindi`}
            color="primary"
            style={{marginRight: 10}}
            ></Chip>
            <Chip clickable
              size="small"
              variant="outlined" 
              label={`Mathematics`}
              color="primary"
              ></Chip>
            </div>
            <br/>
            <div>
              <Typography variant={"h1"}>Revision of Algebra</Typography>
              <Typography variant={"h6"} style={{marginTop: -7}}>Jul 20, 2021 | 1 hour 30 minutes</Typography>
            </div>
            <br/>
            <div>
              <Typography variant={"subtitle2"}>This class covers the basic concepts of algebra in depth for you to crack your next examination.</Typography>
            </div>
            <div class="button-wrapper">
          <Stream
            theme={{}}
            minimal={true}
            title={<> <span><PlayArrowIcon style={{margin: -9}} /></span> <span style={{paddingLeft: 10}}> Watch now </span></>}
            testMode={true}
            data={{
              name: name || "User",
              room: room || "demo",
              record: norecord ? false : true,
              type: consumer ? "consumer" :  "producer"
            }}
            chat={true}
            accessKey="6Ope8Cve"
            onEnded={() => {
              window.setTimeout(() => {
                // setEnded(true);
              }, 10000)
            }}
          />
          <CopyToClipboard onCopy={() => notyf.success("Link copied.")} text={window.location.href}>
            <a style={{marginLeft: 20, paddingTop:2, paddingBottom:2, minHeight: 38}} class="button button-landing-mix w-button">
              Share <ShareIcon style={{fontSize: 20, marginLeft: 10}} />
            </a>
          </CopyToClipboard>
          </div>
        </Card>
      </Grid>
    </Grid>
    </>
  )
}
