export const getTimeLocale = (t) => {
  //@ts-ignore
  let secondsPassed = new Date(t * 1000);
  return (secondsPassed.toLocaleString(undefined, { hour: 'numeric', minute: 'numeric', hour12: true }));
}
export const getTimeLocaleWithDate = (t) => {

  let secondsPassed = new Date(t * 1000);
  return (secondsPassed.toLocaleString(undefined, { hour: 'numeric', minute: 'numeric', day: 'numeric', month: 'long', hour12: true }));
}
export function getTimeDiff(ts) {
  ts = parseInt(ts);
  if (ts < 60) {
    return `${ts} seconds`
  }
  if (ts / 60 < 60) {
    return `${Math.ceil(ts / 60)} minutes`
  }
  if (ts / 3600 < 24) {
    return `${Math.ceil(ts / 3600)} hours`
  }
  return `${Math.ceil(ts / (3600 * 24))} days`
}
