import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useDispatch } from 'react-redux';
import { setUser } from '../store/user/actions';
import { Typography } from '@material-ui/core';
import { Header } from './felix/Header';

const useStyles = makeStyles((theme) =>
  createStyles({
    loginCol: {
    },
    root: {
      height: '100vh'
    },
    filler: {
      background: 'linear-gradient(45deg, #192a56 30%, #273c75 90%)',
      display: "flex",
      justifyContent: "center",
      flexDirection: "column"
    }
  }));

export const Demo = function(props) {
  return (
    <>
    <Header />
    <div class="account-wrapper">
        <div class="form-information2">
        <div class="testimonial-section-dark">
    <div class="container">
      
        <div data-w-id="4ceda9e1-1abb-9aaa-4d2f-7b8d8d21a060" class="w-layout-grid feature-grid-small">
          <div class="testimoinal-card-dark"><img src="../images/Star-Group-Blue.svg" loading="lazy" alt="" class="star-rating" />
            <p class="body-large-white">The educator Demo is ideal for comapnies looking for a student teacher interaction experience. This includes teacher specific features like screenshare, whiteboard, slides, chat etc.</p>
            <div class="author-wrapper">
              <a href={`/demo/educator?room=${Math.ceil(Math.random() * 100000)}`} class="button button-landing-mix w-button">Explore Educator Demo</a>
            </div>
          </div>
          <div class="testimoinal-card-dark"><img src="../images/Star-Group-Blue.svg" loading="lazy" alt="" class="star-rating" />
            <p class="body-large-white">The interview platform demo is ideal for companies looking for a seamless one to one conversation while hiring candidates.</p>
            <div class="author-wrapper">
              <a href={`/demo/interview?room=${Math.ceil(Math.random() * 100000)}`} class="button button-landing-mix w-button">Explore Interview Demo</a>
            </div>
          </div>
        </div>
        <div data-w-id="4ceda9e1-1abb-9aaa-4d2f-7b8d8d21a093" class="background-dark-800"></div>
      </div>
  </div>
        </div>
      </div>
    </>
  );
};
