import { AppState } from "..";
import { Org, Usage } from "./types";

export const currentOrgSelector = (store: AppState): Org => store.organization.currentOrganization;
export const orgsSelector = (store: AppState): Org[] => store.organization.organizations;
export const usageSelector = (store: AppState): Usage[] => store.organization.currentOrganization?.usage || [];
export const orgsLoadingSelector = (store: AppState): boolean => store.organization.isLoading;
export const currentOrgRoomsLoadingSelector = (store: AppState): boolean => store.organization.currentOrganization.roomsLoading || false;
export const currentOrgRtmpRoomsLoadingSelector = (store: AppState): boolean => store.organization.currentOrganization.rtmpRoomsLoading || false;
export const currentOrgUsageLoadingSelector = (store: AppState): boolean => store.organization.currentOrganization.usageLoading || false;
