
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// javascipt plugin for creating charts
import Chart from "chart.js";
import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
import componentStyles from "./assets/theme/components/cards/charts/card-dark.js";
// @material-ui/icons components
// core components
import { chartExample1, chartOptions, parseOptions } from "./variables/chart.js";


const useStyles = makeStyles(componentStyles);

export function StatsChartDark({ title, data, subtitle }) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (index) => {
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };
  return (
    <>
      <Card
        classes={{
          root: classes.cardRoot + " " + classes.cardRootBgGradient,
        }}
      >
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing=".0625rem"
                  marginBottom=".25rem!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[400]}>
                    {subtitle}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h2"
                  marginBottom="0!important"
                >
                  <Box component="span" color={theme.palette.white.main}>
                    {title}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                  {/* <Button
                  variant="contained"
                  color="primary"
                  component={Box}
                  marginRight="1rem!important"
                  onClick={() => toggleNavs(1)}
                  classes={{
                    root: activeNav === 1 ? "" : classes.buttonRootUnselected,
                  }}
                >
                  Month
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => toggleNavs(2)}
                  classes={{
                    root: activeNav === 2 ? "" : classes.buttonRootUnselected,
                  }}
                >
                  Week
                </Button> */}
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent>
          <Box position="relative" height="350px">
            <Line
              data={data}
              options={chartExample1.options}
              getDatasetAtEvent={(e) => console.log(e)}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

