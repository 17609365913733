import { Grid } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { RoomsTable } from '../components/argon/RoomsTable';
import { RtmpRoomsTable } from "../components/argon/RtmpRoomsTable";
import SimpleHeader from '../components/argon/SimpleHeader';
import { UsageChart } from '../components/argon/UsageChart';
import { OrgData } from '../components/Organization/OrgData';

const useStyles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
}));

export const Usage = () => {
  const classes = useStyles();
  return (
    <>
      <SimpleHeader section="Usage" subsection="Dashboard" />
      {/* {JSON.stringify(organizations)}
      {JSON.stringify(currentOrganization)} */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={12}>
            <UsageChart />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <OrgData />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <RtmpRoomsTable />
            <br/>
            <RoomsTable />
            <br/>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
