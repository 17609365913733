import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
// import accountRoutes from "routes/accountOwner";
import adminRoutes from "../../routes";
import { Loader } from "../Loader";
import componentStyles from "./assets/theme/layouts/dashboard";
import AdminFooter from "./Footer";
// @material-ui/icons components
// core components
import AdminNavbar from "./Navbar";
import Sidebar from "./Sidebar";

const useStyles = makeStyles(componentStyles);

const Admin = () => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const [sidebarOpenResponsive, setSidebarOpenResponsive] = React.useState(
    false
  );

  useEffect(() => {
    history.push('/dashboard/rooms');
  }, [])

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [location]);


  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/dashboard") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={(props) => <>
              <AdminNavbar
                openSidebarResponsive={() => setSidebarOpenResponsive(true)}
              />
              <prop.component {...props} />
            </>
            }
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Box display="flex">
        <Sidebar
          routes={adminRoutes}
          openResponsive={sidebarOpenResponsive}
          closeSidebarResponsive={() => setSidebarOpenResponsive(false)}
          logo={{
            innerLink: "/index",
            imgSrc: require("./assets/img/brand/logo.png"),
            imgAlt: "...",
          }}
        />
        <Box position="relative" flex="1" className={classes.mainContent}>
          <Switch>
            {getRoutes(adminRoutes)}
            <Redirect from="*" to="/dashboard/rooms" />
          </Switch>
          <Container
            component={Box}
            classes={{ root: classes.containerRoot }}
          >
            <AdminFooter />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Admin;
