import * as firebase from "firebase/app";

const prodConfig = {
  apiKey: "AIzaSyCBtuT9VE9as3ighJTKSj8qCfQEDGq9i0E",
  authDomain: "stream-eb4c0.firebaseapp.com",
  projectId: "stream-eb4c0",
  storageBucket: "stream-eb4c0.appspot.com",
  messagingSenderId: "641412075510",
  appId: "1:641412075510:web:86fd38bb141fb367b501d7",
  measurementId: "G-WBFXXW76TZ"
};

firebase.initializeApp(prodConfig);