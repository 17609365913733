import React from 'react';
import DashboardLayout from "../argon/Dashboard";

export const Dashboard = () => {
  return (
    <div>
      <DashboardLayout />
    </div>
  )
}
