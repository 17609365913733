import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useDispatch } from 'react-redux';
import { setUser } from '../store/user/actions';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    loginCol: {
    },
    root: {
      height: '100vh'
    },
    filler: {
      background: 'linear-gradient(45deg, #192a56 30%, #273c75 90%)',
      display: "flex",
      justifyContent: "center",
      flexDirection: "column"
    }
  }));

function SignInPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch()
  // const location = useLocation();

  const uiConfig= {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/app/home',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          // Forces account selection even when one account
          // is available.
          prompt: 'select_account'
        }
      },
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        // If a user signed in with email link, ?showPromo=1234 can be obtained from
        // window.location.href.
        dispatch(setUser({
          name: authResult.user.displayName,
          email: authResult.user.email,
          phone: authResult.user.phoneNumber,
          loggedIn: true,
          loading: false,
          id: authResult.user.uid
        }));

        // const state: any = location.state;

        // if (history.action === 'REPLACE' && state && state.referrer) {
        //   history.push(state.referrer);
        // }
        // else if (!redirectUrl) {
        //   props.history.push("/");
        // }
        return false;
      }
    },
  };

  return (
    <>
      <div class="account-wrapper" >
      <div class="form-account">
      <div class="register-form-wrap" style={{marginTop: -50}}>
        <a href="/" class="logo-link w-inline-block"><img src="/webflow/images/Felix-Mark.svg" loading="lazy" alt="" class="logo-mark" /></a>
        <div class="register-header-wrap">
          <h4>Login</h4>
          <p class="body-medium">Integrate live video + audio into your app</p>
        </div>
        {/* <a href="http://google.com" target="_blank" class="account-external-login w-inline-block"><img src="/webflow/images/Google-Logo-Mark.svg" loading="lazy" alt="" class="google-mark" />
          <div class="body-button">Sign up with Google</div>
        </a> */}
        {/* <div class="account-detail-wrapper">
          <div class="account-line"></div>
          <div class="detail-text">or Sign in with Email</div>
          <div class="account-line"></div>
        </div>
        <div class="form-block-register w-form">
          <form id="email-form" name="email-form" data-name="Email Form">
            <div class="label-wrap"><label for="Email">Email<span class="required-span">*</span></label><input type="email" class="text-field w-input" maxlength="256" name="Email" data-name="Email" placeholder="mail@website.com" id="Email" /></div>
            <div class="label-wrap"><label for="Password">Password<span class="required-span">*</span></label><input type="password" class="text-field w-input" maxlength="256" name="Password" data-name="Password" placeholder="Min. 8 character" id="Password" /></div><input type="submit" value="Login" data-wait="Please wait..." class="form-button w-button" />
          </form>
          <div class="success-message w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div class="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div> */}
        <div style={{ marginTop: -20}}>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}></StyledFirebaseAuth>
        </div>
        {/* <div class="account-link-wrap">
          <div class="register-account">Not registered yet? <a href="../account/register.html">Create an Account</a>
          </div>
        </div> */}
      </div>
    </div>
    <div class="form-information"><img src="/webflow/images/Asset-Cards.png" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 82vw, (max-width: 991px) 530px, 45vw" srcset="/webflow/images/Asset-Cards-p-500.png 500w, /webflow/images/Asset-Cards.png 526w" alt="" class="account-asset" />
      <div class="account-info-wrapper">
        <h4 class="h4-title-white">One line API<br/>for integrating live media.</h4>
        <p class="body-white-medium">Consistent quality and experience across<br/>all platforms and devices.</p>
      </div>
    </div>
  </div>
  <div data-w-id="e793c717-36b9-aa04-6858-7cfbc1b3e6c2" class="loader">
    <div data-w-id="377aff38-52d5-6f93-c036-331b72335f3a" data-animation-type="lottie" data-src="../documents/Felix-Loader.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="1.6349682350413481" data-duration="0" class="page-loader-animation"></div>
  </div>
    </>
  );
};

export default SignInPage;