import React, { useState } from 'react'
import { Button, Card, Typography, Grid, makeStyles, LinearProgress } from '@material-ui/core';
import firebase from "firebase/app";
import { ValidatedName } from './ValidatedInput';
import { useDispatch } from 'react-redux';
import { createOrganization } from '../../store/organization/actions';
import { Notyf } from 'notyf';

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: 'center'
  },
  container: {
    minHeight: 200, 
    minWidth: 400,
    maxWidth: 1000,
    padding: 20,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" 
  }
}))
export const Intro = () => {
  const classes = useStyles();
  const [org, setOrg] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const notyf = new Notyf();

  const createOrg = () => {
    if (!org) {
      notyf.error('Please provide a name for your organization.');
      return;
    }
    setLoading(true);
    dispatch(createOrganization(org));    
  }

  return (
    <div>
      <Card className={classes.container}>
        {loading && <LinearProgress />}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h2">
            Please create an organization to continue.
          </Typography>
        </div>
        <ValidatedName
          size="medium"
          placeholder="Organization name"
          value={org}
          setValue={setOrg}
        />
          <Grid className={classes.center}>
            <Button disabled={loading} variant="contained" style={{ maxWidth: 200 }} onClick={createOrg}>
              Create
            </Button>
          </Grid>
      </Card>
    </div>
  )
}
