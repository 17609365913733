import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// @material-ui/core components
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
// core components
import componentStyles from "./assets/theme/components/headers/simple-header";

//@ts-ignore
const useStyles = makeStyles(componentStyles);

const DropdownComponent = ({ dropdowns }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        aria-controls={"simple-menu-"}
        aria-haspopup="true"
        onClick={handleClick}
        // size="small"
        component={Button}
        width="2rem!important"
        height="2rem!important"
        minWidth="2rem!important"
        minHeight="2rem!important"
      >
        {/* <Box
          component={() => <Button variant="contained" size="small">Audits</Button>}
          width="1.25rem!important"
          height="1.25rem!important"
          position="relative"
          top="2px"
          color={"gray"}
        /> */}
      </Box>
      <Menu
        id={"simple-menu-"}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {dropdowns.map(d => <MenuItem onClick={() => {
          d.onClick()
          handleClose();
        }}>{d.title}</MenuItem>)}
      </Menu>
    </>
  );
};


const SimpleHeader = ({ section, subsection, btns = [], hideRoute = false, dropdowns = [] }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.header}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <Grid
            container
            component={Box}
            alignItems="center"
            paddingTop="1.5rem"
            paddingBottom="1.5rem"
          >
            <Grid item xs={7} lg={6} className={classes.gridItem}>
              <Link to="/dashboard/">
                <Typography
                  variant="h2"
                  component="h6"
                  className={clsx(
                    classes.displayInlineBlock,
                    classes.mb0,
                    classes.textWhite
                  )}
                >
                  {section}
                </Typography>
              </Link>
              <Breadcrumbs
                separator="&raquo;"
                aria-label="breadcrumb"
                classes={{
                  root: classes.breadcrumbRoot,
                  li: classes.breadcrumbLi,
                  ol: classes.breadcrumbOl,
                  separator: classes.breadcrumbSeparator,
                }}
              >
                <Link
                  color="inherit"
                  to="/dashboard"
                >
                  {subsection}
                </Link>
                {!hideRoute && <>

                  <Typography
                    component="span"
                    className={classes.breadcrumbActive}
                  >
                    {section}
                  </Typography>
                </>}
              </Breadcrumbs>
            </Grid>
            <Grid item xs={5} lg={6} component={Box} textAlign="right">
              <div className={classes.right}>
                {btns && <>{btns.map(btn => <Button
                  variant="contained"
                  // size="small"
                  className={classes.buttonRoot}
                  onClick={btn.onClick}
                >
                  {btn.title}
                </Button>)}</>}
                {dropdowns && <DropdownComponent dropdowns={dropdowns} />}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

SimpleHeader.propTypes = {
  section: PropTypes.string,
  subsection: PropTypes.string,
};

export default SimpleHeader;
