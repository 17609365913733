import DashboardIcon from '@material-ui/icons/Dashboard';
import FolderIcon from '@material-ui/icons/Folder';
import React from "react";
import { TitleDecorator } from "../components/TitleDecorator";
import Stats from "../views/stats";
import RtmpRoom from "../views/RtmpRoom";
import { Usage } from "../views/Usage";
var routes = [
  {
    collapse: true,
    name: "Dashboard",
    icon: FolderIcon,
    iconColor: "Info",
    state: "campaignsCollapse",
    views: [
      {
        path: "/room/:room_id",
        hideFromSidebar: true,
        name: "Rooms",
        icon: DashboardIcon,
        iconColor: "Primary",
        component: () => <TitleDecorator title={"Dashboard"}> <Stats /> </TitleDecorator>,
        layout: "/dashboard",
      },
      {
        path: "/rtmp_room/:room_id",
        hideFromSidebar: true,
        name: "Rooms",
        icon: DashboardIcon,
        iconColor: "Primary",
        component: () => <TitleDecorator title={"Dashboard"}> <RtmpRoom /> </TitleDecorator>,
        layout: "/dashboard",
      },
      {
        path: "/rooms",
        name: "Rooms",
        icon: DashboardIcon,
        iconColor: "Primary",
        component: () => <TitleDecorator title={"Dashboard"}> <Usage /> </TitleDecorator>,
        layout: "/dashboard",
      }
    ]
  }
];


export default routes;

