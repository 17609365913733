import { Card, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  link: {
    color: "gray",
    textDecoration: "underline",
    "&:hover": {
      color: "gray",
    }
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  appBar: {
    position: 'relative',
  }
}));

export const Recordings = ({ recordings }) => {
  // const currentOrg = useSelector(currentOrgSelector)

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  if (!recordings || recordings.length === 0) {
    return <Card style={{ minHeight: 200, display: "flex", justifyContent: "center", flexDirection: "column" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h2">
          Recording not processed yet.
        </Typography>
      </div>
    </Card>
  }
  return (
    <Card>
      {Object.keys(recordings).map((row, key) => (
        <>
          <Card>
            <video controls src={recordings[row].url} width="100%" ></video>
          </Card>
        </>
      ))}
    </Card>

  );
}
