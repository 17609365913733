import { Card, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { getTimeDiff, getTimeLocale } from "../utils";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  link: {
    color: "gray",
    textDecoration: "underline",
    "&:hover": {
      color: "gray",
    }
  }
}));

export const UserTable = ({ users = [], startTime }) => {
  // const currentOrg = useSelector(currentOrgSelector)
  const classes = useStyles();
  //const history = useHistory();
  const theme = useTheme();

  if (!users.length) {
    return <Card style={{ minHeight: 200, display: "flex", justifyContent: "center", flexDirection: "column" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h2">
          No one has joined yet!
        </Typography>
      </div>
    </Card>
  }
  return (
    <Card>
      <TableContainer className={classes.table} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Joined at</TableCell>
              <TableCell align="right">Duration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(users).map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <Box
                    fontSize=".875rem"
                    component="span"
                    className="name"
                  >
                    {users[row].data?.name}
                  </Box>
                </TableCell>

                <TableCell align="right">
                  {users[row].data?.type}
                </TableCell>

                <TableCell align="right">
                  {getTimeLocale(users[row].start_time)}
                </TableCell>
                <TableCell align="right">
                  {users[row].end_time ? getTimeDiff(users[row].end_time - users[row].start_time) : <></>}
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>

  );
}
