
import { OrgReducer, OrgActions, UPDATE_ORG, UPDATE_ORGS } from "./types";

const INITIAL_STATE: OrgReducer = {
  isLoading: true,
  organizations: [],
  currentOrganization: {
    isLoading: true,
    roomsLoading: true,
    rtmpRoomsLoading: true,
    usageLoading: true,
    rooms: [],
    rtmp_rooms: [],
    usage: [],
  }
}

export function organizationReducer(state = INITIAL_STATE, action: OrgActions) {
  switch (action.type) {
    case UPDATE_ORG:
      return {
        ...state,
        currentOrganization: {
          ...state.currentOrganization,
          ...action.payload
        }
      }
    case UPDATE_ORGS:
      return {
        ...state,
        isLoading: false,
        organizations: action.payload
      }
    default:
      return state;
  }
}