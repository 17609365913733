import React from 'react'
import { Header } from './Header'
import { Footer } from './Footer'

export const Pricing = () => {
  return (
    <>
    <Header />
      <div class="header-large">
    <div class="container">
      <div data-w-id="73b25fdd-7542-a470-c366-22cb2138dc6b" class="header-centre">
        <div class="title-light-blue">PRICING</div>
        <h1 class="hero-heading-white">Get Started Now,<br />Pick a Plan Later</h1>
        <p class="body-large-white max-width">Integrate the Stream API to your app in test mode, pay only when you upgrade your application</p>
        <div class="pricing-badge">
          <div>No credit card required</div>
        </div>
      </div>
    </div>
  </div>
  <div class="pricing-section-large">
    <div class="container">
      <div class="title-wrap-centre">
        <div class="title">PRICING PLANS</div>
        <h2>Flexible pricing that scales with your business.</h2>
      </div>
      <div data-duration-in="300" data-duration-out="100" data-w-id="54f744ea-a257-c026-071a-4cfac32b7a86" style={{
        // "-webkit-transform":"translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        // "-moz-transform":"translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        // "-ms-transform":"translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        // transform:"translate3d(0, 55px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
        // opacity:0
      }} class="tabs-saas w-tabs">
        <div class="price-tab w-tab-content">
          <div data-w-tab="Tab 1" class="w-tab-pane w--tab-active">
            <div class="w-layout-grid pricing-grid-saas">
              <div class="pricing-column">
                <div class="pricing-top-block">
                  <div class="price-header">
                    <h4>Lite</h4>
                    <h3>Free<span class="date-span"></span></h3>
                    <div class="body-medium">Hit the ground running.</div>
                  </div>
                  <a href="../company/contact-1.html" class="button-secondary w-button">Get Started</a>
                </div>
                <div class="w-layout-grid check-grid">
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Max 5 users in a room</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Max 2 simultaneous rooms</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Stream watermark on the UI</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">No recording</p>
                  </div>
                </div>
              </div>
              <div class="pricing-column">
                <div class="pricing-top-block">
                  <div class="price-header">
                    <h4>Basic</h4>
                    <h3>$29<span class="date-span">/mo</span></h3>
                    <div class="body-medium">Power-up your business.</div>
                  </div>
                  <a href="/login" class="button w-button">Get Started</a>
                </div>
                <div class="w-layout-grid check-grid">
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">50 Users in a webinar/broadcast</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">20 Users in a multi conference room</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Unlimited Bandwidth</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Whitelabelling</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Priority Support</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">5 hours of Recording</p>
                  </div>
                </div>
              </div>
              <div id="w-node-e843c200-b172-43bd-e8c6-c177e1805a30-1e66427c" class="pricing-column">
                <div class="pricing-top-block">
                  <div class="price-header">
                    <h4>Plus</h4>
                    <h3 class="pricing-number">Enterprise<span class="date-span">/mo</span></h3>
                    <div class="body-medium">Customized just for you.</div>
                  </div>
                  <a href="mailto:harkirat.iitr@gmail.com" class="button-secondary w-button">Get in touch</a>
                </div>
                <div class="w-layout-grid check-grid">
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Unlimited users in a webinar/broadcast</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">50 users in a multi-room conference</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Whitelabelling</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Unlimited Recording</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Priority Support</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">File Uploader</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div data-w-tab="Tab 2" class="w-tab-pane">
            <div class="w-layout-grid pricing-grid-saas">
              <div class="pricing-column">
                <div class="pricing-top-block">
                  <div class="price-header">
                    <h4>Lite</h4>
                    <h3>$129<span class="date-span">/year</span></h3>
                    <div class="body-medium">Hit the ground running.</div>
                  </div>
                  <a href="../company/contact-1.html" class="button-secondary w-button">Start Free Trial</a>
                </div>
                <div class="w-layout-grid check-grid">
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">1 User</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">5GB Bandwidth</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Whitelabelling</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Limited Support</p>
                  </div>
                </div>
              </div>
              <div class="pricing-column">
                <div class="pricing-top-block">
                  <div class="price-header">
                    <h4>Basic</h4>
                    <h3>$169<span class="date-span">/year</span></h3>
                    <div class="body-medium">Power-up your business.</div>
                  </div>
                  <a href="/login" class="button w-button">Get Started</a>
                </div>
                <div class="w-layout-grid check-grid">
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">10 User</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Unlimited Bandwidth</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Whitelabelling</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Priority Support</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">File Uploader</p>
                  </div>
                </div>
              </div>
              <div id="w-node-a9394d2f-603d-f06f-45f8-ca287240b15a-1e66427c" class="pricing-column">
                <div class="pricing-top-block">
                  <div class="price-header">
                    <h4>Plus</h4>
                    <h3 class="pricing-number">$244<span class="date-span">/year</span></h3>
                    <div class="body-medium">Customized just for you.</div>
                  </div>
                  <a href="../company/contact-1.html" class="button-secondary w-button">Start Free Trial</a>
                </div>
                <div class="w-layout-grid check-grid">
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">50 User</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Unlimited Bandwidth</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Whitelabelling</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">Priority Support</p>
                  </div>
                  <div class="check-point"><img src="/webflow/images/Checkbox-Tick.svg" loading="lazy" alt="" class="check-mark" />
                    <p class="body-dark-medium">File Uploader</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br/><br/><br/>
  <div class="cta-section">
    <div class="container">
      <div data-w-id="8efa1d6b-0cbb-76f2-dd5c-f825e151eb57" class="cta-block-light">
        <div class="cta-wrapper">
          <div class="title">LET’S TRY!</div>
          <h3>Start your 7-day free trial</h3>
          <p class="body-large">Felix can help bring your dream website to life with ease.</p>
          <div class="form-block-large w-form">
            <a href="/login" type="submit" data-wait="Please wait..." class="submit-button w-button" >Get Instant Access </a>
            <div class="success-message w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
          <div class="bullet-wrapper-left">
            <div class="bullet-point-large"><img src="/webflow/images/Bullet-Purple.svg" loading="lazy" alt="" class="bullet-point-purple" />
              <p class="body-small">Free trial</p>
            </div>
            <div class="bullet-point-large"><img src="/webflow/images/Bullet-Purple.svg" loading="lazy" alt="" class="bullet-point-purple" />
              <p class="body-small">No credit card required</p>
            </div>
            <div class="bullet-point-large"><img src="/webflow/images/Bullet-Purple.svg" loading="lazy" alt="" class="bullet-point-purple" />
              <p class="body-small">Cancel anytime</p>
            </div>
          </div>
        </div>
        <div class="phone-hand-wrapper"><img src="/webflow/images/Hero-Phone.png" loading="lazy" data-w-id="8efa1d6b-0cbb-76f2-dd5c-f825e151eb77" alt="" class="phone-hand" /></div>
      </div>
    </div>
    <Footer />
  </div> 
    </>
  )
}
