import React from 'react'
import { createStyles, makeStyles, Theme, CircularProgress, Card } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => createStyles({
  root: {
    background: "linear-gradient(87deg," +
    theme.palette.dark.main +
    ",#1a174d)!important",
    minHeight: 400, 
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
}))

export const ChartLoading = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CircularProgress size={20} />
    </Card>
  )
}
