import React from 'react'
import { Link } from 'react-router-dom'
import {Footer} from '../components/felix/Footer'
import { Header } from '../components/felix/Header'

export const Landing = () => {
  return (
    <>
  <Header />
  <div class="header-section">
    <div class="container">
      <div class="header-block-centre">
        <div class="title">ADD VIDEO AND AUDIO TO YOUR APP</div>
        <h1 class="hero-heading">Add live video and audio<br/>with one line of code.</h1>
        <p class="body-large">Everything you need to add live media<br/> to your app with ease.</p>
        <div class="button-wrapper">
          <a href="/pricing" class="button button-landing-mix w-button">See Pricing</a>
          <a href="mailto:harkirat.iitr@gmail.com" class="button-light w-button">Contact Us</a>
        </div>
        <div class="analytics-wrapper-home"><img src="/webflow/images/classroom.png" loading="lazy" sizes="(max-width: 479px) 77vw, (max-width: 767px) 84vw, (max-width: 991px) 85vw, 840px" srcset="/webflow/images/classroom.png 500w, /webflow/images/classroom.png 800w, /webflow/images/classroom.png 1680w" alt="" class="analytics-dashboard" /></div>
      </div>
    </div>
  </div>
  <div class="video-section-dark">
    <div class="container-small">
      <div data-w-id="7b4f050c-151d-ef72-8847-5c31ddddd9ce" class="title-wrap-small-centre">
        <div class="title-blue">DEMO</div>
        <h3 class="h3-title-white">Launch your own branded apps</h3>
        <p class="body-large-300">Consistent quality and experience across all platforms and devices.</p>
      </div>
      <div data-w-id="7b4f050c-151d-ef72-8847-5c31ddddd9d5" class="bullet-wrapper-centre">
        <div class="bullet-point-space"><img src="/webflow/images/Bullet.svg" loading="lazy" alt="" class="bullet-point-purple"/>
          <p class="body-white-medium">Developer friendly APIs</p>
        </div>
        <div class="bullet-point"><img src="/webflow/images/Bullet.svg" loading="lazy" alt="" class="bullet-point-purple" />
          <p class="body-white-medium">Cloud recording</p>
        </div>
      </div>
      <div data-w-id="57bee914-16c3-8caf-c3cd-867209c04f26" class="browser-video">
        <div class="browser-bar">
          <div class="browser-dots"></div>
          <div class="browser-dots"></div>
        </div>
        <div class="browser-window">
          <div class="video-wrapper"><img src="/webflow/images/Video-Thumbnail.jpg" loading="lazy" sizes="(max-width: 767px) 94vw, 700px" srcset="/webflow/images/Video-Thumbnail-p-500.jpeg 500w, /webflow/images/Video-Thumbnail-p-1080.jpeg 1080w, /webflow/images/Video-Thumbnail.jpg 1240w" alt="" class="image-cover" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="customer-section">
    <div class="container">
      <div data-w-id="a27bbd00-1754-36c7-78e5-0729b62b7c7f" class="title-wrap-left">
        <h4>Flexible layouts based on your use case</h4>
        {/* <a href="../company/careers-1.html" class="button-link-small w-inline-block">
          <div>Learn more</div><img src="/webflow/images/Chevron-Brand.svg" loading="lazy" alt="" class="link-arrow" />
        </a> */}
      </div>
      {/* <div data-w-id="a27bbd00-1754-36c7-78e5-0729b62b7c86" class="w-layout-grid logo-grid"><img src="/webflow/images/wetransfer.svg" loading="lazy" alt="" class="logo" /><img src="/webflow/images/stamps.svg" loading="lazy" alt="" class="logo" /><img src="/webflow/images/manter.svg" loading="lazy" alt="" class="logo" /><img src="/webflow/images/sugarane.svg" loading="lazy" alt="" class="logo" /></div> */}
    </div>
  </div>
  <div class="content-section">
    <div class="container">
      <div class="w-layout-grid content-grid">
        <div data-w-id="0005b6e8-a64d-0815-fb18-22a2cbae4825" class="content-block-saas"><img src="/webflow/images/classroom.png" loading="lazy" alt="" /></div>
        <div id="w-node-_0005b6e8-a64d-0815-fb18-22a2cbae4827-cbae4822" data-w-id="0005b6e8-a64d-0815-fb18-22a2cbae4827">
          <div class="title">EDUCATOR-FRIENDLY</div>
          <h3>Educator UI</h3>
          <p class="body-large">Add educator features along with video and audio to your app</p>
          <div class="w-layout-grid bullet-list-grid">
            <div class="bullet-point"><img src="/webflow/images/Bullet.svg" loading="lazy" alt="" class="bullet-point-purple" />
              <p class="body-dark-medium">Drag and Drop Slides</p>
            </div>
            <div class="bullet-point"><img src="/webflow/images/Bullet.svg" loading="lazy" alt="" class="bullet-point-purple" />
              <p class="body-dark-medium">Gather feedback using Emojis</p>
            </div>
            <div class="bullet-point"><img src="/webflow/images/Bullet.svg" loading="lazy" alt="" class="bullet-point-purple" />
              <p class="body-dark-medium">Invite students to join the call as a participant</p>
            </div>
          </div>
          <div class="form-block w-form">
            <div class="success-message w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-section">
    <div class="container">
      <div class="w-layout-grid content-grid">
        <div id="w-node-a7e5dd8b-6dfd-77c0-5676-ae6b7351f1d2-7351f1cf" data-w-id="a7e5dd8b-6dfd-77c0-5676-ae6b7351f1d2">
          <div class="title">MINIMAL UI</div>
          <h3 class="h3-title">Around-like UI</h3>
          <p class="body-large">Add a minimal draggable video/audio layer on top of your existing usecase.</p>
          <div class="w-layout-grid bullet-list-grid">
            <div class="bullet-point"><img src="/webflow/images/Bullet.svg" loading="lazy" alt="" class="bullet-point-purple" />
              <p class="body-dark-medium">Preserve your existing use case with a minimal VideoUI</p>
            </div>
            <div class="bullet-point"><img src="/webflow/images/Bullet.svg" loading="lazy" alt="" class="bullet-point-purple" />
              <p class="body-dark-medium">Drag the stream container <b>around</b> your web-page</p>
            </div>
          </div>
          <div class="author-wrapper"><img src="/webflow/images/Content-Team-Quote.png" loading="lazy" alt="" class="avatar-large" />
            <div>
              <p class="body-quote">&quot;It’s intuitive, functional, easy-to-setup and provides a variety of presets to chose from</p>
              <div class="testimonial-author">Ramanjeet Singh, <span class="text-span">founder @Acadcare</span></div>
            </div>
          </div>
        </div>
        <div data-w-id="a7e5dd8b-6dfd-77c0-5676-ae6b7351f1e4" class="content-image-wrapper"><img src="/webflow/images/Content-Card.svg" loading="lazy" alt="" class="content-message-card" /><img src="/webflow/images/Content-Team.png" loading="lazy" sizes="(max-width: 1170px) 94vw, 1100px" srcset="/webflow/images/Content-Team-p-500.png 500w, /webflow/images/Content-Team.png 1100w" alt="" /></div>
      </div>
    </div>
  </div>
  <div class="content-section">
    <div class="container">
      <div class="w-layout-grid content-grid">
        <div data-w-id="0005b6e8-a64d-0815-fb18-22a2cbae4825" class="content-block-saas"><img src="/webflow/images/Content-Card-Group.svg" loading="lazy" alt="" /></div>
        <div id="w-node-_0005b6e8-a64d-0815-fb18-22a2cbae4827-cbae4822" data-w-id="0005b6e8-a64d-0815-fb18-22a2cbae4827">
          <div class="title">AUDIO-ONLY</div>
          <h3>Audio only UI <br/></h3>
          <p class="body-large">Video seem too intrusive? Try out an audio-only UI.</p>
          <div class="w-layout-grid bullet-list-grid">
            <div class="bullet-point"><img src="/webflow/images/Bullet.svg" loading="lazy" alt="" class="bullet-point-purple" />
              <p class="body-dark-medium">Audio only chat</p>
            </div>
            <div class="bullet-point"><img src="/webflow/images/Bullet.svg" loading="lazy" alt="" class="bullet-point-purple" />
              <p class="body-dark-medium">User moderation.</p>
            </div>
            <div class="bullet-point"><img src="/webflow/images/Bullet.svg" loading="lazy" alt="" class="bullet-point-purple" />
              <p class="body-dark-medium">Feels like you're part of a <b>club</b> or living inside a <b>house</b></p>
            </div>
          </div>
          <div class="form-block w-form">
            <div class="success-message w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="faq-section-dark">
    <div class="container-small">
      <div class="title-wrap-large-centre">
        <div class="title-blue"></div>
        <h3 class="h3-title-white">Features and FAQs</h3>
      </div>
      <div class="w-layout-grid faq-grid">
        <div class="faq-column">
          <div class="faq-block">
            <h4 class="h4-title-white">What all devices are supported?</h4>
            <p class="body-medium-300">We support Web and mobile. <br/> We have provide APIs in <b>React</b>, <b>vanilla JS</b> and <b>React native</b> Next up on our roadmap is Vue, native Android, IOS and Unity SDK</p>
          </div>
          <div class="faq-block">
            <h4 class="h4-title-white">How will I be charged</h4>
            <p class="body-medium-300">Our pricing is based on the number of minutes of media produced and consumed. For more, check the <a href="/pricing">Pricing</a> page</p>
          </div>
          <div class="faq-block">
            <h4 class="h4-title-white">How scalable is the platform? </h4>
            <p class="body-medium-300">We scale infinitely in a one (or constant) to many scenario. There are client-side restrictions that prevent us (or even zoom) from scaling to more than 50 simultaneous users in a multi call scenario.</p>
          </div>
        </div>
        <div class="faq-column padding-top-32">
          <div class="faq-block">
            <h4 class="h4-title-white">What all companies are using Stream?</h4>
            <p class="body-medium-300">Stream is build by WebRTC experts who've built media pipelines for multiple enterprise companies. Our aim is to bring high grade-enterprise ready media flow with minimal lines of code. </p>
          </div>
          <div class="faq-block">
            <h4 class="h4-title-white">Do I get a free trial?</h4>
            <p class="body-medium-300">Your first 10,000 minutes are on the house. Pricing plans kick in after you've integrated the Stream in your application and are satisfied with the integration. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
  </>
  )
}
