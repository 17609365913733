import React from 'react'
import {Stream} from "streamapi";
import { Header } from '../components/felix/Header';

export const InterviewDemo = () => {
  let room = new  URLSearchParams(window.location.search).get("room") || "demo";
  let consumer = new URLSearchParams(window.location.search).get("consumer")
  let norecord = new  URLSearchParams(window.location.search).get("norecord");
  const name = "User";
  return (
    <div>
      <Header />
  <div class="job-section">
    <div class="container">
      <div class="w-layout-grid job-grid-header">
        <div>
          <div class="title">JOB</div>
          <h3>Role</h3>
          <p class="body-large">Uscreen is a SaaS company with a remarkable global team, helping creators and companies monetize their videos online. Video monetization is a fast-growing market, and so far, we have established ourselves as the industry leaders, and we aim to solidify that position. To do so, we’re looking for a new addition to our marketing team: Junior SEO Specialist, to come in and help us execute and scale our SEO and marketing strategies.<br/><br/>Our team is incredibly passionate, smart, supportive, skilled, open to learning, happy to take constructive criticism and implement it. We’re looking for someone who can match that energy and help us scale.</p>
        </div>
        <div>
          <div class="job-information-card">
            <div class="w-layout-grid job-detail-grid">
              <div>
                <div class="title-grey-400">JOB TITLE</div>
                <h5>Junior SEO Specialist</h5>
              </div>
              <div>
                <div class="title-grey-400">LOCATION</div>
                <h5>Remote</h5>
              </div>
              <div>
                <div class="title-grey-400">employment basıs</div>
                <h5>Full Time</h5>
              </div>
            </div>
            <div style={{marginLeft: -5}}>
            <Stream
            theme={{}}
            minimal={true}
            theme={{
              primary: "#5138ee",
              contrast: "white"
            }}
            title={"Join Interview"}
            testMode={true}
            view="around"
            data={{
              name: name || "User",
              room: room || "demo",
              record: norecord ? false : true,
              type: consumer ? "consumer" :  "producer"
            }}
            chat={true}
            accessKey="6Ope8Cve"
            onEnded={() => {
              window.setTimeout(() => {
                // setEnded(true);
              }, 10000)
            }}
          />
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  )
}
