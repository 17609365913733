import React from "react";
import {Helmet} from "react-helmet";

export const TitleDecorator = ({title, children}) => {
  return (
    <div style={{minHeight: "80vh"}}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  )
}