import React from 'react'

export const Header = () => {
  return (
    <>
    <div class="navigation">
    {/* <div class="banner">
      <div class="banner-wrap">
        <div>We're live on Product Hunt.</div>
      </div>
    </div> */}
    <div data-collapse="medium" data-animation="default" data-duration="400" role="banner" class="navigation-default w-nav">
      <div class="navigation-container">
        <div class="navigation-wrapper">
          <a href="../index.html" class="brand-large w-nav-brand"><img width="100" src="/webflow/images/Felix-Logo.svg" alt="" class="logo" /></a>
          <nav role="navigation" class="nav-menu w-nav-menu">
          </nav>
        </div>
        <div class="navigation-button-wrapper">
          <a href="/demo" class="explore">Demo</a>
          <a href="/login" class="button-navigation w-button">Login</a>
          <div class="navigation-menu w-nav-button">
            <div class="w-icon-nav-menu"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

    </>
  )
}
