export const SET_USER = 'SET_USER';

export interface User {
  name: string | null
  email: string | null
  loggedIn: Boolean
  phone: string | null
  loading: Boolean | undefined
  id: string | null
}

interface SetUserAction {
  type: typeof SET_USER
  payload: User
}

export type UserActions = SetUserAction