
import { SET_USER, User, UserActions } from "./types";

const INITIAL_STATE: User = {
  name: null,
  loggedIn: false,
  loading: true,
  phone: null,
  email: null,
  id: null
}

export function userReducer(state = INITIAL_STATE, action: UserActions) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
}