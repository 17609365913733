
import 'notyf/notyf.min.css';
import firebase from "firebase/app";
import "firebase/auth";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import { Dashboard } from "./components/dashboard/Dashboard";
import { Loader } from "./components/Loader";
import Login from "./components/Login";
import { Invite } from "./components/Login/Invite";
import { getOrganizations } from './store/organization/actions';
import { orgsLoadingSelector, orgsSelector } from './store/organization/selector';
import { setUser } from './store/user/actions';
import { userSelector } from './store/user/selector';
import { Landing } from './views/Landing';
import { Pricing } from './components/felix/Pricing';
import { Demo } from './components/Demo';
import { EducatorDemo } from './views/EducatorDemo';
import { InterviewDemo } from "./views/InterviewDemo";

function App() {
  const organizations = useSelector(orgsSelector);
  const organizationsLoading = useSelector(orgsLoadingSelector);

  const dispatch = useDispatch();
  const authState = useSelector(userSelector);

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(async (user) => {
        if (user) {
          dispatch(setUser({
            loading: false,
            name: user.displayName,
            phone: user.phoneNumber,
            email: user.email,
            loggedIn: true,
            id: user.uid
          }));
          dispatch(getOrganizations(user.uid));
        } else {
          dispatch(setUser({
            loading: false,
            name: null,
            phone: null,
            email: null,
            loggedIn: false,
            id: null
          }));
        }
      });

    return function cleanup() {
      unregisterAuthObserver();
    };
  }, [dispatch])

  if (authState.loading) {
    return <Loader />
  }

  if (!authState.loggedIn) {
    return (
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/demo/educator" component={EducatorDemo} />
          <Route path="/demo/interview" component={InterviewDemo} />
          <Route path="/demo" component={Demo} />
          <Route path="/" component={Landing} />
        </Switch>
      </Router>
    )
  }

  if (organizationsLoading) {
    return <Loader />
  }

  if (!organizations || !organizations.length) {
    return <Invite />
  }

  return (
    <Router>
      <Switch>
        <Route path="/demo" component={Demo} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/" component={Dashboard} />
      </Switch>
    </Router>
  );
}

export default App;
