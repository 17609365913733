import { Card, IconButton, InputAdornment, InputLabel, makeStyles, OutlinedInput } from '@material-ui/core';
import DirectionsIcon from '@material-ui/icons/Directions';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Notyf } from 'notyf';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrg } from "../../store/organization/actions";
import { currentOrgSelector } from '../../store/organization/selector';
import { ValidatedUrl } from '../intro/ValidatedInput';
const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: 'center'
  },
  cardRoot: {
    color: "white",
    background:
      "linear-gradient(87deg," +
      theme.palette.primary +
      ",#1a174d)!important",
    padding: 20,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  iconButton: {
    padding: 10,
  }
}))

export const OrgData = () => {
  const classes = useStyles();
  const [org, setOrg] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const notyf = new Notyf();
  const currentOrg = useSelector(currentOrgSelector);
  const [showSecret, setShowSecret] = useState(false);
  const [recordingWebhook, setRecordingWebhook] = useState(currentOrg.recordingWebhook);

  useEffect(() => {
    if (currentOrg.recordingWebhook) {
      setRecordingWebhook(currentOrg.recordingWebhook);
    }
  }, [currentOrg.recordingWebhook]);

  const handleClickShowPassword = () => {
    setShowSecret(x => !x);
  }

  const submitRecordingWebhook = () => {
    if (!recordingWebhook) {
      return notyf.error("Invalid url.");
    }
    const patt = new RegExp("((http|https)://)(www.)?"
      + "[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]"
      + "{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)");
    if (!patt.test(recordingWebhook)) {
      return notyf.error("Invalid url.");
    }
    dispatch(updateOrg({
      recordingWebhook,
      id: currentOrg.id
    }));
  }

  return (
    <div>
      <Card className={classes.cardRoot}>
        <InputLabel>Access key</InputLabel>
        <OutlinedInput
          value={currentOrg.accessKey}
          endAdornment={<><InputAdornment position="end">
          </InputAdornment>
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
              >
                <CopyToClipboard onCopy={() => { notyf.success("Copied to clipboard") }} text={currentOrg.accessKey}>
                  <FileCopyIcon />
                </CopyToClipboard>
              </IconButton>
            </InputAdornment>
          </>}
        />
        <br />
        <InputLabel>Access Secret</InputLabel>
        <OutlinedInput
          value={showSecret ? currentOrg.accessSecret : "******************"}
          endAdornment={<><InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showSecret ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
              >
                <CopyToClipboard onCopy={() => { notyf.success("Copied to clipboard") }} text={currentOrg.accessSecret}>
                  <FileCopyIcon />
                </CopyToClipboard>
              </IconButton>
            </InputAdornment>
          </>}
        />
        <br /><br />
        <InputLabel>Recording Webhook</InputLabel>
        <ValidatedUrl
          value={recordingWebhook}
          setValue={setRecordingWebhook}
          endAdornment={<>
            <InputAdornment position="end">
              <IconButton
                color="primary"
                size="medium"
                className={classes.iconButton}
                aria-label="toggle password visibility"
                onClick={submitRecordingWebhook}
              >
                <DirectionsIcon />
              </IconButton>
            </InputAdornment>
          </>}
        />
      </Card>
    </div>
  )
}
