import React from 'react'

export const Footer = () => {
  return (
    <>
      <div class="footer-section-template">
    <div class="container">
      <div class="w-layout-grid footer-grid">
        <div data-w-id="fc76d04b-c746-1346-60ea-beb1833e8354" class="footer-column">
          <a href="#" class="w-inline-block"><img width="100" src="/webflow/images/Felix-Logo.svg" loading="lazy" alt="" class="footer-logo" /></a>
          <div class="w-layout-grid footer-contact-detail">
            <a href="#" class="w-inline-block">
              <div class="title-grey-400">EXPERTISE</div>
              <h5 class="h5-title-blue">Built by WebRTC experts</h5>

            </a>
          </div>
        </div>
        <div data-w-id="fc76d04b-c746-1346-60ea-beb1833e8361" class="footer-column">
          <div class="title-grey-400">DEMOS ⚡</div>
          <a target="_blank" href={`https://demo.acadcare.com?room=room-${Math.ceil(Math.random() * 10000)}`} class="footer-link w-inline-block">
            <div>Educator demo</div>
          </a>
          <a target="_blank" href={`https://demo.acadcare.com?room=room-${Math.ceil(Math.random() * 10000)}`} class="footer-link w-inline-block">
            <div>Audio only demo</div>
          </a>
          <a target="_blank" href={`https://demo.acadcare.com?room=room-${Math.ceil(Math.random() * 10000)}`} class="footer-link w-inline-block">
            <div>Ecommerce demo</div>
          </a>
        </div>
        <div data-w-id="fc76d04b-c746-1346-60ea-beb1833e8373" class="footer-column">
          <div class="title-grey-400">Quick Start⭐</div>
          <a href="/login" class="footer-link w-inline-block">
            <div>Login</div>
          </a>
          <a href="/pricing" class="footer-link w-inline-block">
            <div>Pricing</div>
          </a>
        </div>
      </div>
      <p data-w-id="fc76d04b-c746-1346-60ea-beb1833e8394" class="body-medium">© Website by <a href="https://www.getstream.in/" target="_blank">Stream</a></p>
      <div data-w-id="fc76d04b-c746-1346-60ea-beb1833e8396" class="footer-line"></div>
    </div>
  </div>
  <div data-w-id="e793c717-36b9-aa04-6858-7cfbc1b3e6c2" class="loader">
    <div data-w-id="377aff38-52d5-6f93-c036-331b72335f3a" data-animation-type="lottie" data-src="../documents/Felix-Loader.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="1.6349682350413481" data-duration="0" class="page-loader-animation"></div>
  </div>
    </>
  )
}
