// import { useParams } from 'react-router-dom';
//firebase.initializeApp(Stats);
import { Grid } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Recordings } from '../components/argon/Recordings';
import SimpleHeader from '../components/argon/SimpleHeader';
import { StartChart } from '../components/argon/StatChart';
import { UserTable } from '../components/argon/UserTable';
import { getTimeLocale, getTimeLocaleWithDate } from '../components/utils';
import { currentOrgSelector } from '../store/organization/selector';

function Stats() {
  // const classes = useStyles()
  const [users, setUsers] = useState([])
  const [recordings, setRecordings] = useState({})
  const db = firebase.firestore();
  const { room_id } = useParams();
  const accessKey = useSelector(currentOrgSelector).accessKey;
  const curOrgId = useSelector(currentOrgSelector).id;

  useEffect(() => {
    fetchRecording();
    fetchUser();
  }, []);


  const _getStartTime = (timestamps) => {
    let smallest = 1000000000000;
    timestamps.map(x => {
      if (x < smallest) {
        smallest = x
      }
    })
    return smallest;
  }

  const _getEndTime = (timestamps) => {
    let largest = -1;
    timestamps.map(x => {
      if (x && x > largest) {
        largest = x;
      }
    })
    if (largest === -1) {
      return new Date() / 1000;
    }
    return largest;
  }

  const startTime = _getStartTime(users.map(x => x.start_time));
  const endTime = _getEndTime(users.map(x => x.end_time));
  const fetchRecording = async () => {
    var snapshot = await db.collection("orgs").doc(accessKey).collection("recordings").where('room', '==', `${room_id}-${accessKey}`).get();
    let data = snapshot.docs.map((doc) => ({ ...doc.data() }));
    setRecordings(data)
  }
  const fetchUser = async () => {
    var snapshot = await db.collection("orgs").doc(curOrgId).collection("sessions").where('room', '==', `${room_id}`).get();
    let data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    data.sort((a, b) => (a.start_time - b.start_time))

    setUsers(data)
  }

  const getChartData = () => {
    let labels = [];
    labels.push(startTime - 100);
    for (let i = 0; i < users.length; i++) {
      if (users[i].start_time)
        labels.push(users[i].start_time)
    }
    for (let i = 0; i < users.length; i++) {
      if (users[i].end_time)
        labels.push(users[i].end_time)
    }
    labels.sort();
    let MAX_VALUES = 50;
    if (labels.length > MAX_VALUES) {
      let labels2 = [];
      labels2.push(labels[0]);
      for (let i = 0; i < MAX_VALUES; i++) {
        labels2.push(labels[Math.floor(i * (labels.length/MAX_VALUES))])
      }
      labels = labels2;
    }
    // labels.push(startTime - ((endTime + ((endTime - startTime)) / LABEL_COUNT)) / LABEL_COUNT);
    const data = [];
    for (let i = 0; i < labels.length; i++) {
      let freq = 0;
      for (let j = 0; j < users.length; j++) {
        if (users[j].start_time <= labels[i] && (!users[j].end_time || (users[j].end_time >= labels[i]))) {
          freq++;
        }
      }
      data.push(freq);
    }
    console.error(labels);
    return {
      labels: labels.map(x => getTimeLocale(x)),
      datasets: [
        {
          label: "Users",
          data: data,
        },
      ]
    }
  }

  return (<>
    <SimpleHeader section={room_id} subsection="Dashboard" />
    <Container
      maxWidth={false}
      component={Box}
      marginTop="-4.5rem"
    // classes={{ root: classes.containerRoot }}
    >
      <Grid container>
        <Grid item xs={12} sm={12} lg={12}>
          <StartChart
            title={endTime - startTime}
            subtitle={"Users"}
            data={getChartData()}
          />
        </Grid>

        <Grid item xs={8} sm={12} md={6} lg={7}>
          <UserTable
            users={users}
            startTime={startTime}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <Recordings
            recordings={recordings}
            startTime={startTime}
          />
        </Grid>
      </Grid>
    </Container>
  </>
  )


}

export default Stats;