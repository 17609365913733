import { Button, Card, Typography, CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from "@material-ui/core/Tooltip";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { currentOrgSelector, currentOrgRoomsLoadingSelector } from '../../store/organization/selector';
import { getTimeString } from './utils';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },  
  root: {
    minHeight: 200, 
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    color: "gray",
    textDecoration: "underline",
    "&:hover": {
      color: "gray",
    }
  },
  center: {
    display: "flex",
    justifyContent: 'center'
  },
}));

export const RoomsTable = () => {
  const currentOrg = useSelector(currentOrgSelector)
  const roomsLoading = useSelector(currentOrgRoomsLoadingSelector)
  const rooms = currentOrg.rooms;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const getTime = (t) => {
    //@ts-ignore
    let secondsPassed = Math.floor((new Date() - new Date(t)) / 1000);
    return getTimeString(secondsPassed);
  }

  if (roomsLoading) {
    return <Card className={classes.root}>
      <CircularProgress size={20} />
    </Card>
  }

  if (!rooms || !rooms.length) {
    return <Card style={{ minHeight: 200, display: "flex", justifyContent: "center", flexDirection: "column" }}>
      <div>
        <div className={classes.center}>
          <Typography variant="h2">
            You have no WebRTC rooms rooms yet!
          </Typography>
        </div>
        <div className={classes.center}>
          <Button size="small" target="_blank" href="https://app.gitbook.com/@acadcare/s/stream/" variant="contained">
            Check docs
          </Button>
        </div>
      </div>
    </Card>
  }

  return (
    <Card>
      <TableContainer className={classes.table} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Room</TableCell>
              <TableCell align="right">Producer usage</TableCell>
              <TableCell align="right">Consumer usage</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rooms.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  <Box
                    fontSize=".875rem"
                    component="span"
                    className="name"
                  >
                    {row.id}
                  </Box>
                </TableCell>
                <TableCell align="right">
                  {row.producerMinutes || 0} minutes
                </TableCell>
                <TableCell align="right">
                  {row.consumerMinutes || 0} minutes
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Go">
                    <IconButton onClick={() => { history.push(`/dashboard/room/${row.id}`) }} aria-label="edit">
                      <Box
                        component={ArrowForwardIcon}
                        width="1.25rem!important"
                        height="1.25rem!important"
                        color={"gray"}
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
