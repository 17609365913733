import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import componentStyles from "./assets/theme/components/navbars/navbar";
import clsx from "clsx";
import UserDropdown from "./UserDropdown";
import { AccountsDropdown } from "./AccountsDropdown";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

//@ts-ignore
const useStyles = makeStyles(componentStyles);

export default function AdminNavbar({ openSidebarResponsive}) {
  const classes = useStyles();
  const theme = useTheme();
  const [showSearch, setShowSearch] = React.useState(false);
  const history = useHistory();
  return (
    <>
      <AppBar
        position="relative"
        elevation={0}
        classes={{ root: classes.appBarRoot }}
      >
          <Container
            maxWidth={false}
            disableGutters={true}
            component={Box}
            classes={{ root: classes.containerRoot }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              marginTop="1rem"
              marginBottom="1rem"
            >
              <Box
                display="flex"
                alignItems="center"
                width="auto"
                marginRight="1rem"
              >
                <AccountsDropdown history={history} />
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={clsx(classes.marginLeftAuto, {
                  [classes.displayNone]: showSearch,
                })}
              >
                <UserDropdown />
              </Box>
            </Box>
          </Container>
      </AppBar>
    </>
  );
}
AdminNavbar.defaultProps = {
  openSidebarResponsive: () => { },
};

AdminNavbar.propTypes = {
  // use this to make the Sidebar open on responsive mode
  openSidebarResponsive: PropTypes.func.isRequired,
};
