import { FormGroup } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrg } from '../../store/organization/actions';
import { currentOrgSelector, orgsSelector } from '../../store/organization/selector';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    color: "black",
    background: "white"
  },
  selectEmpty: {
    marginTop: theme.spacing(10),
  },
}));

export const AccountsDropdown = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const organizations = useSelector(orgsSelector);
  const activeOrg = useSelector(currentOrgSelector);

  if (organizations.length === 0) {
    return <></>
  }

  return (
    <FormGroup className={classes.formControl}>
      <Select
        value={activeOrg.id}
        onChange={async (e) => {
          // setActiveAccount({ account_id:  })
          dispatch(getOrg(e.target.value));
          history.push("/dashboard/rooms")
        }}
      >
        {organizations.map(account => <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>)}
      </Select>
    </FormGroup>
  )
}
