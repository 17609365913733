import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStylesFacebook = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "50vh"
    },
  }),
);
export const Loader = () => {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        disableShrink
      />      
    </div>
  )
}
