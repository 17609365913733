
import { Test, TestActions, UPDATE_RESPONSE } from "./types";

const INITIAL_STATE: Test = {
  response: [],
  isLoading: true
}

export function testReducer(state = INITIAL_STATE, action: TestActions) {
  switch (action.type) {
    case UPDATE_RESPONSE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
}