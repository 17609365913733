import React from 'react'
import { useSelector } from 'react-redux'
import { usageSelector, currentOrgUsageLoadingSelector } from '../../store/organization/selector'
import { StatsChartDark } from './StatsChartDark'
import { ChartLoading } from '../charts/ChartLoading'

export const UsageChart = () => {
  const usage = useSelector(usageSelector)
  const usageLoading = useSelector(currentOrgUsageLoadingSelector);
  const getChartData = () => {
    const labels = usage.map(x => x.date);
    let data = usage.map(x => x.producerMinutes + x.consumerMinutes);
    return {
      labels,
      datasets: [
        {
          label: "Users",
          data: data,
        },
      ]
    }
  }
  if (usageLoading) {
    return <ChartLoading />
  }
  return (
    <div>
      <StatsChartDark
        title={"Usage"}
        subtitle={"This week's"}
        data={getChartData()}
      />
    </div>
  )
}
