import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons components
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import firebase from "firebase/app";
import "firebase/auth";
import React from "react";
import { useSelector } from "react-redux";
import { nameSelector } from "../../store/user/selector";
// core components
import componentStyles from "./assets/theme/dropdowns/user-dropdown";
///@ts-ignore
const useStyles = makeStyles(componentStyles);

export default function UserDropdown() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const name = useSelector(nameSelector);
  const image = `https://ui-avatars.com/api/?name=${name}&background=0D8ABC&color=fff`;

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "dropdowns-user-dropdown-id";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <Divider component="div" classes={{ root: classes.dividerRoot }} /> */}
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={handleMenuClose}
      >
        <Box
          component={DirectionsRun}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span onClick={() => {
          firebase
            .auth()
            .signOut()
        }}>Logout</span>
      </Box>
    </Menu>
  );

  return (
    <>
      <Button
        onClick={handleProfileMenuOpen}
        color="inherit"
        classes={{
          label: classes.buttonLabel,
          root: classes.buttonRoot,
        }}
      >
        <Avatar
          alt="..."
          src={image || `https://ui-avatars.com/api/?name=${name}`}
          classes={{
            root: classes.avatarRoot,
          }}
        />
        <Hidden mdDown> {name} </Hidden>
      </Button>
      {renderMenu}
    </>
  );
}
