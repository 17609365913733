import red from '@material-ui/core/colors/red';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import App from './App';
import "./config/firebaseConfig";
import './index.css';
import * as serviceWorker from './serviceWorker';
import store from './store/index';
import theme2 from "./components/argon/assets/theme/theme"
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    tertiary: {
      green: string;
      greenText: string;
      red: string;
      redText: string;
      gray: string;
      lightGray: string;
      lightestGray: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    tertiary?: {
      green: string;
      greenText: string;
      red: string;
      redText: string;
      gray: string;
      lightGray: string;
      lightestGray: string;
    };
  }
}
// All the following keys are optional.
// We try our best to provide a great default value.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2c3e50'
    },
    secondary: {
      main: '#ecf0f1'
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  tertiary: {
    red: "#EDBCA1",
    redText: "#7A3823",
    green: "#C2EDA1",
    greenText: "#406323",
    lightGray: "#c4c4c4",
    gray: "#5f5f5f",
    lightestGray: "rgb(245, 245, 245)"
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme2}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
